<template>
    <div class="page">
        <Tables :tableName="tableName"
                :tableData="roleList"
                :totalPage="totalPage"
                :isLoading="isLoading"
                :showRefresh="true"
                :showAdd="$hasAccess('system.role/add')"
                addName="添加角色"
                :tree-config="{}"
                @getList="getList"
                @addItemInTable="addRole">

            <!--表格内容-->
            <vxe-table-column slot="table-item" field="role_name" title="角色" align="center"/>
            <vxe-table-column slot="table-item" field="desc" title="说明" align="center"/>
            <vxe-table-column slot="table-item" title="操作" width="180" align="center">
                <template v-slot="{ row }">
                    <el-button size="small" icon="el-icon-edit" plain
                               @click="editRole(row)" :disabled="row.pid === 0">编辑
                    </el-button>
                    <el-button size="small" icon="el-icon-delete" plain
                               @click="deleteItem(row)" :disabled="row.pid === 0">删除
                    </el-button>
                </template>
            </vxe-table-column>
        </Tables>

        <!-- 新增 -->
        <el-dialog title="添加"
                   :visible.sync="addDialogShow"
                   width="800px">
            <el-form :model="addDialogFormData" :rules="rules" ref="addDialogForm"
                     label-width="120px"
                     class="form">
                <el-form-item label="父级" prop="pid">
                    <el-select v-model="addDialogFormData.pid" placeholder="请选择父级" size="small"
                               @change="handleRoleChange">
                        <el-option v-for="item in roleList" :label="item.role_name"
                                   :value="item.role_id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="新角色名称" prop="role_name">
                    <el-input v-model="addDialogFormData.role_name" placeholder="请输入新角色名称"
                              size="small" style="width: 70%"></el-input>
                </el-form-item>
                <el-form-item label="菜单" class="form-item">
                    <el-tree ref="tree"
                             :data="nodeList"
                             :props="permissonProps"
                             :render-content="renderContent"
                             :default-expand-all="true"
                             :default-checked-keys="permissionCheckedList"
                             show-checkbox
                             node-key="node_id"
                             class="permission-tree"/>
                </el-form-item>
                <el-form-item label="描述">
                    <el-input v-model="addDialogFormData.desc" placeholder="请输入描述"
                              size="small" style="width: 70%"></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" plain icon="el-icon-check"
                           @click="handleConfirm('addDialogForm','add')"
            v-if="$hasAccess('system.role/add')">保 存
                </el-button>
                <el-button size="small" plain @click="closeDialog" icon="el-icon-close">取 消</el-button>
            </div>
        </el-dialog>

        <!-- 编辑 -->
        <el-dialog title="编辑"
                   :visible.sync="editDialogShow"
                   width="800px">
            <el-form :model="editDialogFormData" :rules="rules" ref="editDialogForm"
                     label-width="120px"
                     class="form">
                <el-form-item label="父级" prop="pid">
                    <el-select v-model="editDialogFormData.pid" placeholder="请选择父级" size="small"
                               @change="handleRoleChange">
                        <el-option v-for="item in roleList" :label="item.role_name"
                                   :value="item.role_id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="新角色名称" prop="role_name">
                    <el-input v-model="editDialogFormData.role_name" placeholder="请输入请新角色名称"
                              size="small" style="width: 70%"></el-input>
                </el-form-item>
                <el-form-item label="菜单" class="form-item">
                    <el-tree ref="tree"
                             :data="nodeList"
                             :props="permissonProps"
                             :render-content="renderContent"
                             :default-expand-all="true"
                             :default-checked-keys="permissionCheckedList"
                             show-checkbox
                             node-key="node_id"
                             class="permission-tree"/>
                </el-form-item>
                <el-form-item label="描述">
                    <el-input v-model="editDialogFormData.desc" placeholder="请输入描述"
                              size="small" style="width: 70%"></el-input>
                </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" plain
                           @click="handleConfirm('editDialogForm','edit')" icon="el-icon-check"
            v-if="$hasAccess('system.role/edit')">保 存
                </el-button>
                <el-button size="small" plain @click="closeDialog" icon="el-icon-close">取 消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import API from '@/service/api'
import Tables from '@/components/tables'
import { flatArray, getHasChildNodeIdList } from '@/utils/util'

export default {
  name: 'system-role-list',
  components: {
    Tables
  },
  data () {
    return {
      tableName: '角色管理',
      roleList: [],
      nodeList: [],
      totalPage: -1,
      isLoading: false,
      addDialogShow: false,
      addDialogFormData: {
        pid: '',
        role_name: '',
        desc: ''
      },
      editDialogShow: false,
      editDialogFormData: {
        role_id: '',
        pid: '',
        role_name: '',
        desc: ''
      },
      rules: {
        pid: [
          { required: true, message: '请选择父级', trigger: 'blur' }
        ],
        role_name: [
          { required: true, message: '请输入新角色名称！', trigger: 'blur' }
        ]
      },
      permissonProps: {
        label: 'title',
        children: 'child'
      },
      permissionCheckedList: []
    }
  },
  methods: {
    renderContent (h, { node, data }) {
      let className = ''
      if (data.child && data.child.length == 0) {
        className = 'especially'
      }
      return (< div
        class
          = { className } > { data.title
        } <
      /div>)
    },
    changeTreeClass () {
      // 找到之前做标记的class
      let classDomList = document.getElementsByClassName('especially')
      // 改变这几个样式
      for (let i = 0; i < classDomList.length; i++) {
        classDomList[i].parentNode.style.cssText = 'float: left'
        classDomList[i].parentNode.className = 'el-tree-node__content option-wrapper'
        classDomList[i].parentNode.parentNode.parentNode.style.marginLeft = '70px'
      }
    },
    // 拉取数据
    getList (currentPage, pageSize, searchFormData) {
      const params = {
        token: this.$store.state.user.token
      }
      this.isLoading = true
      // 拉取角色列表
      API.System.GetRoleList(params).then(res => {
        this.roleList = res.map(item => {
          item.role_name = item.role_name.replace(new RegExp(/&nbsp;/g), '')
          return item
        })
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
      })
    },
    // 重置表单数据
    resetFormData () {
      this.addDialogFormData = {
        pid: 1,
        role_name: '',
        desc: '',
        rules: '*'
      }
      this.editDialogFormData = {
        role_id: '',
        pid: '',
        role_name: '',
        desc: '',
        rules: ''
      }
      if (this.$refs.addDialogForm) {
        this.$refs.addDialogForm.resetFields()
      }
      if (this.$refs.editDialogForm) {
        this.$refs.editDialogForm.resetFields()
      }
    },
    // 删除
    deleteItem (row) {
      this.$confirm('确定要删除该角色吗', '提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          token: this.$store.state.user.token,
          role_id: row.role_id
        }
        API.System.DeleteRole(params).then(res => {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.getList(0, 10, '')
        })
      })
    },
    // 新增
    addRole () {
      this.resetFormData()
      // 获取节点
      API.System.GetNodeList({ token: this.$store.state.user.token }).then(res => {
        this.nodeList = res
        this.addDialogShow = true
        setTimeout(() => {
          if (this.addDialogFormData.rules === '*') {
            this.$refs.tree.setCheckedNodes(this.nodeList)
          } else {
            this.$refs.tree.setCheckedKeys(this.addDialogFormData.rules.split(','))
          }
        }, 100)
        setTimeout(() => {
          this.changeTreeClass()
        }, 200)
      })
    },
    // 点击编辑
    editRole (row) {
      this.resetFormData()
      const params = {
        token: this.$store.state.user.token,
        role_id: row.role_id
      }
      // 获取详情
      API.System.GetRoleDetail(params).then(res => {
        this.editDialogFormData = {
          role_id: res.role_id,
          pid: res.pid,
          role_name: res.role_name,
          desc: res.desc,
          rules: res.rules
        }
        // 获取节点
        API.System.GetNodeList({ token: this.$store.state.user.token }).then(res => {
          this.nodeList = res
          this.editDialogShow = true

          const nodeIds = this.editDialogFormData.rules.split(',')
          const nodes = flatArray(this.nodeList)
          const hasChildNodeIds = getHasChildNodeIdList(nodes)
          let newNodeIds = []
          for (let nodeId of nodeIds) {
            if (!hasChildNodeIds.includes(nodeId)) {
              newNodeIds.push(nodeId)
            }
          }

          setTimeout(() => {
            if (this.editDialogFormData.rules === '*') {
              this.$refs.tree.setCheckedNodes(this.nodeList)
            } else {
              this.$refs.tree.setCheckedKeys(newNodeIds)
            }
          }, 100)

          setTimeout(() => {
            this.changeTreeClass()
          }, 200)
        })
      })
    },
    // 点击确认
    handleConfirm (formName, type) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let rules = ''
          const nodes1 = this.$refs.tree.getCheckedNodes()
          const nodes2 = this.$refs.tree.getHalfCheckedNodes()
          let nodes = [...nodes1, ...nodes2]
          for (let i = 0; i < nodes.length; i++) {
            if (i === nodes.length - 1) {
              rules = rules + nodes[i].node_id
            } else {
              rules = rules + nodes[i].node_id + ','
            }
          }
          if (type === 'add') {
            let form = this.addDialogFormData
            form.rules = rules
            const params = {
              token: this.$store.state.user.token,
              form: JSON.stringify(form)
            }
            API.System.AddRole(params).then(res => {
              this.$notify({
                title: '成功',
                message: '添加成功',
                type: 'success'
              })
              this.getList(0, 10, '')
              this.addDialogShow = false
            })
          } else if (type === 'edit') {
            let form = this.editDialogFormData
            form.rules = rules
            const params = {
              token: this.$store.state.user.token,
              form: JSON.stringify(form)
            }
            API.System.EditRole(params).then(res => {
              this.$notify({
                title: '成功',
                message: '保存成功，重新登录即可生效！',
                type: 'success'
              })
              this.getList(0, 10, '')
              this.editDialogShow = false
            })
          }
        }
      })
    },
    // 关闭弹窗
    closeDialog () {
      this.addDialogShow = false
      this.editDialogShow = false
      this.resetFormData()
    },
    // 处理角色改变
    handleRoleChange (role_id) {
      let role = null
      for (let item of this.roleList) {
        if (item.role_id === role_id) {
          role = item
        }
      }

      const nodeIds = role.rules.split(',')
      const nodes = flatArray(this.nodeList)
      const hasChildNodeIds = getHasChildNodeIdList(nodes)
      let newNodeIds = []
      for (let nodeId of nodeIds) {
        if (!hasChildNodeIds.includes(nodeId)) {
          newNodeIds.push(nodeId)
        }
      }

      setTimeout(() => {
        if (role.rules === '*') {
          this.$refs.tree.setCheckedNodes(this.nodeList)
        } else {
          this.$refs.tree.setCheckedKeys(newNodeIds)
        }
      }, 100)
    }
  }
}
</script>

<style>
    .el-tree {
        margin-top: 6px !important;
    }
</style>

<style lang="scss" scoped>
        .normal {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ee8812;

            .circle {
                width: 10px;
                height: 10px;
                background: #ee8812;
                border-radius: 100%;
                margin-right: 10px;
            }
        }

        .hide {
            display: flex;
            align-items: center;
            justify-content: center;
            color: gray;

            .circle {
                width: 10px;
                height: 10px;
                background: gray;
                border-radius: 100%;
                margin-right: 10px;
            }
        }
</style>
